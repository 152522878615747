import React from 'react';

const TranslationIcon = () => {
	return (
		<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_9089_5051)">
				<path
					d="M6.8175 10.071L6.165 12H4.5L7.293 4.5H9.219L12 12H10.2495L9.597 10.071H6.8175ZM9.2685 8.967L8.25 5.934H8.1765L7.158 8.967H9.2685Z"
					fill="#4D4D4D"
				/>
				<path
					d="M0 3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0L13.5 0C14.2956 0 15.0587 0.316071 15.6213 0.87868C16.1839 1.44129 16.5 2.20435 16.5 3V7.5H21C21.7956 7.5 22.5587 7.81607 23.1213 8.37868C23.6839 8.94129 24 9.70435 24 10.5V21C24 21.7956 23.6839 22.5587 23.1213 23.1213C22.5587 23.6839 21.7956 24 21 24H10.5C9.70435 24 8.94129 23.6839 8.37868 23.1213C7.81607 22.5587 7.5 21.7956 7.5 21V16.5H3C2.20435 16.5 1.44129 16.1839 0.87868 15.6213C0.316071 15.0587 0 14.2956 0 13.5V3ZM3 1.5C2.60218 1.5 2.22064 1.65804 1.93934 1.93934C1.65804 2.22064 1.5 2.60218 1.5 3V13.5C1.5 13.8978 1.65804 14.2794 1.93934 14.5607C2.22064 14.842 2.60218 15 3 15H13.5C13.8978 15 14.2794 14.842 14.5607 14.5607C14.842 14.2794 15 13.8978 15 13.5V3C15 2.60218 14.842 2.22064 14.5607 1.93934C14.2794 1.65804 13.8978 1.5 13.5 1.5H3ZM13.707 16.4925C13.996 16.9435 14.311 17.3665 14.652 17.7615C13.53 18.624 12.1425 19.263 10.5 19.6995C10.767 20.025 11.1765 20.652 11.3325 21C13.02 20.4615 14.4525 19.734 15.6615 18.759C16.827 19.7565 18.27 20.5065 20.0565 20.967C20.256 20.586 20.6775 19.9575 21 19.632C19.3125 19.2525 17.9145 18.591 16.77 17.706C17.7915 16.5855 18.603 15.2295 19.2015 13.5705H21V12H16.5V13.5705H17.6475C17.1705 14.8365 16.5375 15.8895 15.7395 16.7655C15.5189 16.5308 15.3111 16.2845 15.117 16.0275C14.6934 16.2982 14.2085 16.4581 13.707 16.4925Z"
					fill="#4D4D4D"
				/>
			</g>
			<defs>
				<clipPath id="clip0_9089_5051">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default TranslationIcon;
