import React from 'react';

const PlusFilledIcon = ({ size = 24, color = 'currentColor', ...props }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				fill={color}
				stroke={color}
				strokeWidth="1.5"
			/>
			<path d="M15 12H12M12 12H9M12 12V9M12 12V15" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
		</svg>
	);
};

export default PlusFilledIcon;
